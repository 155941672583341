import { environmentKey } from "~/injection-symbols"

export const useTracking = () => {
  const environment = inject(environmentKey)

  if (environment === "production") {
    const route = useRoute()

    onMounted(() => {
      hubspotTrack("setPath", useRoute().path)
      hubspotTrack("trackPageView")
      metaTrack("track", "PageView")
      googleTagTrack("event", "page_view", { page_path: useRoute().path })
    })

    useHead({
      script: [
        {
          type: "text/javascript",
          id: "hs-script-loader",
          async: true,
          defer: true,
          src: "//js.hs-scripts.com/8184800.js",
        },
        {
          id: "hs-script-init",
          innerHTML: `var _hsq=window._hsq=window._hsq||[];_hsq.push(['setPath','${route.path}']);_hsq.push(['setContentType', 'site-page']);var _hsp=window._hsp=window._hsp||[];`,
        },
        {
          id: "gtag-script-init",
          innerHTML:
            "window.dataLayer=window.dataLayer||[];function gtag(){dataLayer.push(arguments);}gtag('set','allow_ad_personalization_signals',false);gtag('js',new Date());gtag('config','G-P99T4SPWC5');",
        },
        {
          id: "meta-script-loader-init",
          innerHTML:
            "!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('consent', 'revoke');fbq('init', '1019144132716050');fbq('track', 'PageView');",
        },
      ],
      link: [
        // per https://developer.chrome.com/docs/lighthouse/performance/uses-rel-preconnect
        { rel: "preconnect", href: "https://fonts.googleapis.com" },
        { rel: "preconnect", href: "https://js.hs-analytics.net" },
        { rel: "preconnect", href: "https://js.hsadspixel.net" },
        { rel: "preconnect", href: "https://js.hscollectedforms.net" },
        { rel: "preconnect", href: "https://js.hs-banner.com" },
        { rel: "preconnect", href: "https://connect.facebook.net" },
      ],
    })

    withConsent(
      consent =>
        consent.categories.advertisement
        && useHead({ script: [{ id: "gtag-script-loader", async: true, src: "https://www.googletagmanager.com/gtag/js?id=G-P99T4SPWC5" }] }),
    )
    withConsent(consent => {
      metaTrack("consent", consent.categories.advertisement ? "grant" : "revoke")
      googleTagTrack("set", "allow_ad_personalization_signals", consent.categories.advertisement)
    })

    useDevice().isCrawler
    && useHead({ script: [{ id: "gtag-script-loader", async: true, src: "https://www.googletagmanager.com/gtag/js?id=G-P99T4SPWC5" }] })
  } else {
    useSeoMeta({ robots: "noindex" })
    console.warn("tracking disabled in non-production environment")
  }
}
